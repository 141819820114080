.container-cube {
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 5000px;
    min-height: 300px;
}

.cube {
    position: relative;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    animation: cube 10s linear 0s infinite;
}

.cube div {
    width: 200px;
    height: 200px;
    background-size: cover;
    position: absolute;
    opacity: 1.0;
    top: 0;
}

.cube-top {
    background-image: url(../images/skills-html5.png);
    transform: rotateX(90deg) translateZ(100px);

}

.cube-bottom {
    background-image: url(../images/skills-mongodb.png);
    transform: rotateX(-90deg) translateZ(100px);
}

.cube-left {
    background-image: url(../images/skills-react.png);
    transform: rotateY(-90deg) translateZ(100px);
}

.cube-right {
    background-image: url(../images/skills-javascript.png);
    transform: rotateY(90deg) translateZ(100px);


}

.cube-back {
    background-image: url(../images/skills-css3.png);
    transform: rotateY(180deg) translateZ(100px);
}

.cube-front {
    background-image: url(../images/skills-node.png);
    transform: translateZ(100px);
}

@keyframes cube {
    from {
        transform: rotateY(0deg) rotateX(0deg)
    }

    to {
        transform: rotateY(360deg) rotateX(720deg)
    }
}
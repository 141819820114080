:root {
  --max-width: 1100px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-break: normal;
}

html {
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
